/**
 * 마이데이터 모니모통장 store
 */

import _orderBy from 'lodash/orderBy';
// 모니모통장 조회 api
import { accountMonimoInfo } from '@/api/mdp/ac';

import TypeUtil from '@/utils/type-util';

export const state = () => ({
  mnmBankBookList: [], // 모니모통장 리스트
});

export const getters = {
  /**
   * 모니모통장 계좌리스트 반환
   */
  getMnmBankBookList(state) {
    return state.mnmBankBookList;
  },
  /**
   * 계좌번호로 모니모통장 찾기
   * @param {Array} mnmAccountList 모니모통장 계좌리스트
   * @param {String} acNum 계좌번호
   * @returns {Object} 파라미터로 받은 계좌번호에 해당하는 모니모통장 반환
   */
  getMnmAcByAccountNum:
    state =>
    (mnmAccountList = [], acNum = '') => {
      return mnmAccountList?.find(ac => ac.acnoe?.substring(0, 20) === acNum.substring(0, 20)) ?? {};
    },
  /**
   * 모니모통장 정렬
   * 우선순위 (모니모통장 isMnmBankBook, 모니모통장 자동적립계좌 isMbbMmnyRvAc, 대표계좌 dgAcYn)
   * @param {Array} accountList 계좌리스트
   * @param {Array} condition 정렬 대상 (default: 모니모통장, 자동적립계좌, 대표계좌)
   * @param {Array} orders order 조건
   * @returns {Array} 정렬된 계좌리스트
   */
  getSortedMnmBankBookList:
    state =>
    (
      accountList = [],
      condition = ['isMnmBankBook', 'isMbbMmnyRvAc', ({ dgAcYn }) => dgAcYn === 'Y'],
      orders = ['desc', 'desc', 'desc'],
    ) => {
      return _orderBy(accountList, condition, orders);
    },
};

export const mutations = {
  setMnmBankBookList(state, payload) {
    state.mnmBankBookList = payload;
  },
};

export const actions = {
  /**
   * 모니모통장 계좌 조회
   * @param {Array|Object|String} accountData
   */
  async fetchMnmAccounts({ commit }, accountData) {
    this.$log.log('[마데모니모통장-store-action] 모니모통장 accountData : ', accountData);
    if (!_hasMnmBankBookCode(accountData)) {
      commit('setMnmBankBookList', []);
      return;
    }

    const mnmAcInfoRes = await accountMonimoInfo();
    this.$log.log('[마데모니모통장-store-action] 모니모통장 API accountMonimoInfo : ', mnmAcInfoRes);
    let mnmBankBookList = [];
    if (mnmAcInfoRes && mnmAcInfoRes.success && mnmAcInfoRes.payload) {
      mnmBankBookList = mnmAcInfoRes.payload.hppEPfm00008OvoSub1List;
    } else {
      mnmBankBookList = [];
    }
    commit('setMnmBankBookList', mnmBankBookList || []);
  },
};
/**
 * Argument 들어온 값에 모니모통장 코드 포함여부 확인 함수
 * @param {Array|Object|String} accountData
 * @returns {Boolean}
 */
function _hasMnmBankBookCode(accountData) {
  if (!accountData) return false;
  // 배열인지 확인
  if (TypeUtil.isArray(accountData) && !TypeUtil.isEmptyArray(accountData)) {
    // 배열 데이터 Object 안에 orgCode, openBankingOrgCode, opbkUFnitC 값으로 확인
    return accountData.some(item => {
      const { orgCode, openBankingOrgCode, opbkUFnitC } = item;
      const code = orgCode || openBankingOrgCode || opbkUFnitC;
      return _isKbBankCode(code) || _isSSSCode(code);
    });
  }
  // Object 안에 orgCode, openBankingOrgCode, opbkUFnitC 값으로 확인
  else if (TypeUtil.isPlainObject(accountData) && !TypeUtil.isEmptyObject(accountData)) {
    const { orgCode, openBankingOrgCode, opbkUFnitC } = accountData;
    const code = orgCode || openBankingOrgCode || opbkUFnitC;
    return _isKbBankCode(code) || _isSSSCode(code);
  }
  return _isKbBankCode(accountData) || _isSSSCode(accountData);
}
/**
 * 국민은행 코드여부 확인
 * @param {String} code
 * @returns {Boolean}
 */
function _isKbBankCode(code) {
  const kbBankCode = new Set(['A1AAEO0000', '004']);
  return kbBankCode.has(code);
}
/**
 * 삼성증권 코드여부 확인
 * @param {String} code
 * @returns {Boolean}
 */
function _isSSSCode(code) {
  const sssCode = new Set(['C1AAEY0000', '240']);
  return sssCode.has(code);
}
