import { cdList, srnListWithId, selCurDateTime } from '@/api/pfm/cmn';
import { sessionInfo } from '@/api/pfm/anony';

import URLParser, { isPfmUrl, toMnmAflcmpC } from '@/utils/url-parser';
import Log from '@/utils/log';
import StorageUtil from '@/utils/storage-util';
import TypeUtil from '@/utils/type-util';

/********************************
 * 모니모 공통 상수 정의
 ********************************/
export const CS_CENTER_TEL = {
  MONIMO: '1588-7882',
  MONIMO_TXT: '15887882',
};

/********************************
 * 공통 유틸
 ********************************/
// 환경구분코드
const ENVR_DV_C = {
  DEV: 'L',
  TEST: 'T',
  STAGE: 'V',
  PROD: 'P',
};

// 접속 네트워크 환경 구분
const NET_KIND = {
  LTE: 'lte', // Public 네트워크 망
  WIFI: 'wifi', // 내부 AP 망
  WIFI_SSL: 'wifiSsl', // 내부 AP SSL 망
};

/********************************
 * 모바일 관련 파라미터, 함수
 * (온고브릿지를 제거하면서 commonUtil로 이관)
 ********************************/
// Mobile에서 넘겨주는 userAgent값을 Map으로 저장
const userAgentMap = {};
const userAgentArr = navigator.userAgent?.split(';');
if (userAgentArr && userAgentArr.length > 0) {
  for (let i = 0; i < userAgentArr.length; i++) {
    const data = userAgentArr[i];
    const txtArr = data?.split('=');
    if (txtArr && txtArr.length === 2) {
      userAgentMap[txtArr[0]] = txtArr[1];
    }
  }
}
// 앱/타겟 버전을 비교하는 함수
const versionCompare = (appVersion, targetVersion) => {
  if (Number(appVersion[0]) > Number(targetVersion[0])) {
    return true;
  } else if (Number(appVersion[0]) === Number(targetVersion[0]) && Number(appVersion[1]) > Number(targetVersion[1])) {
    return true;
  } else if (
    Number(appVersion[0]) === Number(targetVersion[0]) &&
    Number(appVersion[1]) === Number(targetVersion[1]) &&
    Number(appVersion[2]) >= Number(targetVersion[2])
  ) {
    return true;
  }
  return false;
};

const commonUtil = {
  // AS-IS 디채 scard.common.js에 있는 함수들 START
  /** 환경구분코드 */
  ENVR_DV_C,

  /** 접속 네트워크 환경 구분 */
  NET_KIND,

  // 로컬개발 여부 with 로컬서버
  isDev: (context = window?.$nuxt?.context) =>
    (context?.env?.ENVR_DV_C || process.env.ENVR_DV_C) === ENVR_DV_C.DEV ||
    process.env.NODE_ENV === 'dev' ||
    process.env.NODE_ENV === 'devlocal',
  // 로컬개발 여부 with 개발계 서버
  isDevlocal: () => process.env.NODE_ENV === 'devlocal',
  // 개발계 여부
  isTest: (context = window?.$nuxt?.context) => (context?.env?.ENVR_DV_C || process.env.ENVR_DV_C) === ENVR_DV_C.TEST,
  // 스테이징 여부
  isStage: (context = window?.$nuxt?.context) => (context?.env?.ENVR_DV_C || process.env.ENVR_DV_C) === ENVR_DV_C.STAGE,
  // 운영계 여부
  isProd: (context = window?.$nuxt?.context) => (context?.env?.ENVR_DV_C || process.env.ENVR_DV_C) === ENVR_DV_C.PROD,

  // 외부망 여부
  isExternal: () => commonUtil.getAppInfo('netKind') === NET_KIND.LTE,

  // 내부 AP 여부
  isInnerAp: () => commonUtil.getAppInfo('netKind') === NET_KIND.WIFI,

  // 내부 AP(SSL) 여부
  isInnerApSsl: () => commonUtil.getAppInfo('netKind') === NET_KIND.WIFI_SSL,

  // 신규 모니모 브릿지 사용가능 여부체크 공통 함수
  isMonimoBridgeApp: () => {
    if (commonUtil.isMobileApp()) {
      return commonUtil.activateService({ aos: '10.5.5', ios: '10.5.5' });
    } else {
      // 개발에서는 신규브릿지로 테스트
      return true;
    }
  },

  // 모니모통장 브릿지 사용가능 여부체크 공통 함수
  isMBankApp: () => {
    if (commonUtil.isMobileApp()) {
      return commonUtil.activateService({ aos: '10.5.6', ios: '10.5.6' });
    } else {
      // 개발에서는 모니모통장 브릿지로 테스트
      return true;
    }
  },
  /**
   * 클라이언트 디바이스가 모바일인지 체크한다
   *
   * @return {boolean} 모바일이면 true
   * @example
   **/
  isMobile() {
    if (/Android/i.test(navigator.appVersion)) {
      return true;
    } else if (/iphone|ipod|ipad/i.test(navigator.appVersion)) {
      return true;
    } else {
      return false;
    }
  },

  /**
   * 클라이언트 디바이스가 모바일앱인지 체크한다
   *
   * @return {boolean} 모바일앱이면 true
   * @example
   **/
  isMobileApp() {
    return commonUtil.getAppInfo('mblSappYn') === 'Y' || location.search.includes('mblSappYn=Y');
  },

  /**
   * userAgent에 저장된 앱 데이터를 가져온다
   *
   * @param {String} key userAgent 데이터의 키값
   *   mblSappYn // 모바일앱 여부 (Y 고정)
   *   mblOsDvC  // 모바일OS 구분 (01:Android,  02:iOS)
   *   mblOsVerNm // 모바일OS 버전
   *   mblSappNm  // 모바일앱 이름 (Monimo 고정)
   *   mblSappVerNm // 모바일앱 버전명
   *   mblDvcDrmNo // 모바일디바이스식별번호
   *   mblSappKndDvC // 앱종류 구분코드 (PFM 고정)
   *   apstDvc // 앱스토어 구분코드 (2:기본, 3:T스토어)
   *   netKind // 연결네트워크 구분 (wifi:개발서버 Wifi http 접속, wifiSsl:개발서버 Wifi https 접속, lte:기타)
   *   isDebug // 디버그앱 여부 ('Y':디버그앱, null:일반앱)
   * @returns
   */
  getAppInfo(key) {
    return userAgentMap[key];
  },

  /**
   * 특정 서비스가 배포된 앱에서만 동작해야할때 이 유틸을 사용한다.
   * @param {Object} param {
   *    key: OS종류 (aos or ios)
   *    value: 버전정보 (ex. "10.0.2")
   * }
   * @returns {Boolean} 앱 버전에 따른 서비스 가능여부 리턴
   */
  activateService(deviceObj) {
    let activateService = false;
    const deviceCode = this.getAppInfo('mblOsDvC');
    const appVersion = this.getAppInfo('mblSappVerNm');
    // appVersion 을 가져오지 못하면 false 처리
    if (appVersion == null) return activateService;

    if (deviceCode === '01') {
      // AOS
      // 버전 정보가 없으면 false 리턴
      if (deviceObj.aos == null) return activateService;

      let targetProdVerArr, targetTestVerArr;
      if (typeof deviceObj.aos === 'object') {
        // 타겟 버전을 운영 버전과 테스트 버전으로 구성된 Array로 입력했을때
        for (let i = 0; i < deviceObj.aos.length; i++) {
          const targetVersion = deviceObj.aos[i];
          if (targetVersion.split('.').length === 3) {
            targetProdVerArr = targetVersion.split('.');
          } else {
            targetTestVerArr = targetVersion.split('.');
          }
        }
      } else {
        // 타겟 버전을 한개만 입력 했을때
        const targetVersion = deviceObj.aos.split('.');
        if (targetVersion.length === 3) {
          targetProdVerArr = targetVersion;
        } else {
          targetTestVerArr = targetVersion;
        }
      }
      const versionArr = appVersion.split('.');
      Log.log('[공통-CommonUtil] AppVersionArr: ', versionArr);
      if (versionArr.length === 3) {
        // AOS 운영 버전일때
        // target 버전 이상에서만 해당 기능이 동작함
        Log.log('[공통-CommonUtil] ProdArr: ', targetProdVerArr);
        activateService = versionCompare(versionArr, targetProdVerArr);
      } else if (targetTestVerArr != null) {
        // AOS 테스트 버전일때
        // target 버전 이상에서만 해당 기능이 동작함
        Log.log('[공통-CommonUtil] TestArr: ', targetTestVerArr);
        activateService = Number(versionArr[0]) >= Number(targetTestVerArr[0]);
      }
    } else if (deviceCode === '02') {
      // IOS
      if (deviceObj.ios == null) return activateService;

      const versionArr = appVersion.split('.');
      const targetVerArr = deviceObj.ios.split('.');
      Log.log('[공통-CommonUtil] AppVersionArr: ', versionArr);
      Log.log('[공통-CommonUtil] TargetVerArr: ', targetVerArr);
      activateService = versionCompare(versionArr, targetVerArr);
    }
    return activateService;
  },

  /**
   * 네임스페이스를 생성한다.
   * @param {String} 네임스페이스
   * @returns 생성된 네임스페이스의 parent 네임스페이스 객체
   */
  createNamespace(ns) {
    const names = ns.split('.');
    let i;
    let parent = window;
    const length = names.length;

    for (i = 0; i < length; i++) {
      if (typeof parent[names[i]] === 'undefined') {
        parent[names[i]] = {};
      }
      if (i < length - 1) {
        parent = parent[names[i]];
      }
    }
    return parent;
  },

  /**
   * 현재 페이지의 화면ID를 가져온다.
   * @param {String} routeName 라우트 이름 이나 경로 둘다 가능
   * @returns
   */
  getScrnId(routeName = window?.$nuxt?.$route?.name) {
    return routeName?.substring(routeName?.lastIndexOf('/') + 1).slice(0, 12) || 'UPFMCO0101M0';
  },
  /**
   * WCMS 컨텐츠의 anchor 태그에 클릭 이벤트 바인딩
   * @param {HTML} $el WCMS 컨텐츠 element
   */
  adjustALink($el, context = window?.$nuxt?.context) {
    const anchorEl = $el?.querySelectorAll('a');
    Log.log('[공통-CommonUtil] adjustALink anchorEl: ', anchorEl);
    if (anchorEl && anchorEl.length > 0) {
      Array.from(anchorEl).forEach(el => {
        el.addEventListener('click', e => {
          // 기본 동작은 막는다.
          e.preventDefault();

          const href = el.getAttribute('href');
          const target = el.getAttribute('target');
          Log.log(`[공통-CommonUtil] adjustALink href: ${href}, target: ${target}`);

          // 링크 존재 시
          if (href) {
            const isInternalUrl = isPfmUrl(href);
            if (target === '_blank') {
              if (isInternalUrl) {
                Log.log('[공통-CommonUtil] adjustALink 내부 도메인! - 새창 열기');
                // 내부 도메인을 새 웹뷰로 여는 경우의 관계사코드 필요???
                context?.$ongobridge.openNewBrowser({ url: href, mnmAflcmpC: '' });
              } else {
                Log.log('[공통-CommonUtil] adjustALink 외부 도메인! - 새창 열기');
                // 기본적으로 새로운 웹뷰를 띄우는 동작으로 정의
                context?.$ongobridge.openNewBrowser({ url: href, mnmAflcmpC: toMnmAflcmpC(href) });
              }
            } else if (target === '_pfmWcmsOutlink') {
              /**
               * openOutBrowser 분기 추가
               *
               * 참고) WCMS 에서 받아온 HTMl 코드는 순수 HTML 이기 때문에 vue 스크립트 기능을 실행할 수 없다.
               *       이슈 번호 - PFM-3289
               */
              if (commonUtil.isMobileApp()) {
                Log.log('[공통-CommonUtil] adjustALink 내부 도메인! - 모바일앱 => openOutBrowser 열기');
                context?.$ongobridge.openOutBrowser({ url: href });
              } else {
                Log.log('[공통-CommonUtil] adjustALink 내부 도메인! - 모바일웹 => href 이동');
                window.location.href = href;
              }
              /**
               * pfmWcmsNewBrowser 분기 추가 PFM-5113
               * 참고) 1.헤더 없이 새창 열기.
               *      2.웹에선 이동 하지 않는걸로 현업 협의.
               */
            } else if (target === '_pfmWcmsNewBrowser') {
              if (isInternalUrl) {
                if (commonUtil.isMobileApp()) {
                  Log.log('[공통-CommonUtil] adjustALink 내부 도메인! - 헤더 없이 새창 열기');
                  context?.$ongobridge.openNewBrowser({ url: href, mnmAflcmpC: '', headerYn: 'N' });
                }
              }
            } else if (isInternalUrl) {
              Log.log('[공통-CommonUtil] adjustALink 내부 도메인! - 현재창 이동');
              (context?.$router || context?.app?.router)?.push(new URLParser(href).tokenize().pathname);
            } else {
              Log.log('[공통-CommonUtil] adjustALink 외부 도메인! - 현재창 이동');
              context?.$ongobridge.openCurBrowser({ url: href, mnmAflcmpC: toMnmAflcmpC(href) });
            }
          }
        });
      });
    }
    return $el;
  },

  /**
   * 웹뷰 뒤로가기 처리를 한다. (첫 페이지인 경우 웹뷰 닫기 브릿지 호출)
   * @param {Object} Nuxt.js Context or Vue인스턴스 VM(Vue Model)
   * @param {Number|String} step
   */
  goBack(context = window?.$nuxt?.context, step = 1) {
    Log.log('[공통-CommonUtil] goBack context: ', context);
    try {
      // 첫번쨰 페이지인 경우 ( firstYn=Y )
      let firstYn = (context.$route || context.route).query?.firstYn;
      let pushYn = (context.$route || context.route).query?.pushYn;
      if (TypeUtil.isArray(firstYn) && !TypeUtil.isEmptyArray(firstYn)) {
        firstYn = firstYn[0];
      }
      if (TypeUtil.isArray(pushYn) && !TypeUtil.isEmptyArray(pushYn)) {
        pushYn = pushYn[0];
      }
      if (pushYn && pushYn.includes('Y')) {
        if (commonUtil.isMonimoBridgeApp()) {
          context?.$mnmBridge?.callBridge(context.$mnmBridge.OPEN_NATIVE, { target: 'asset' });
        } else {
          context?.$ongobridge?.openNative({ target: 'asset' });
        }
      }
      // if (firstYn === 'Y') {
      // IOS에서 위젯 송금 버튼 클릭 이동시 버그가 있어서 includes 처리함
      else if (firstYn && firstYn.includes('Y')) {
        if (commonUtil.isMonimoBridgeApp()) {
          context?.$mnmBridge?.callBridge(context.$mnmBridge.CLOSE_WEBVIEW);
        } else {
          context?.$ongobridge?.closeCurBrowser();
        }
      } else {
        (context?.$router || context?.app?.router).go(-Math.abs(Number(step)));
      }
    } catch (err) {
      Log.log.error(err);
    }
  },

  /**
   * Object Deep copy
   * @param {Object} obj clone할 Object
   * @return {Object} clone 된 Object
   */
  getCloneObject(obj) {
    const cloneObj = {};
    for (const key in obj) {
      if (obj[key] != null && typeof obj[key] === 'object') {
        cloneObj[key] = commonUtil.getCloneObject(obj[key]);
      } else {
        cloneObj[key] = obj[key];
      }
    }
    return cloneObj;
  },

  /**
   * Array 내 같은 Object를 찾아서 index 반환
   * @param {Array} dataArr 기준 데이터
   * @param {Object} obj 타겟 object
   * @returns 같은 object index
   */
  findIndex(dataArr, obj) {
    let returnIdx = -1;
    if (dataArr != null && dataArr.length > 0) {
      for (let i = 0; i < dataArr.length; i++) {
        const data = dataArr[i];
        let flag = true;
        for (const key in obj) {
          if (data[key] !== obj[key]) {
            flag = false;
            break;
          }
        }
        if (flag) {
          returnIdx = i;
          break;
        }
      }
    }
    return returnIdx;
  },

  /**
   * Object key, value가 같은지 확인하는 함수
   * 단, 1depth Object만 비교 가능함
   * @param {Object} obj1 object1
   * @param {Object} obj2 object2
   * @returns {Boolean}
   */
  isEqualObj(obj1, obj2) {
    const keys1 = Object.keys(obj1);
    const keys2 = Object.keys(obj2);
    if (keys1.length !== keys2.length) {
      return false;
    }
    for (const key of keys1) {
      if (obj1[key] !== obj2[key]) {
        return false;
      }
    }
    return true;
  },
  /**
   * 간편모드 앱버전 여부 확인
   */
  isSmplModeSappVer(context = window?.$nuxt?.context) {
    let isSmplVer = false;
    if (commonUtil.isMobileApp()) {
      isSmplVer = context.$appUtil.activateService({ aos: '10.3.12', ios: '10.3.12' });
    }
    context.$log.log(`[공통-CommonUtil] [isSmplModeSappVer] simpleMode 적용앱여부: `, isSmplVer);
    return isSmplVer;
  },
};

export default commonUtil;

/**
 * 공통 코드 호출 후 리스트를 반환한다.
 * @param {String | Array} code 공통코드
 * @param {Array} exCdArr 컬럼 itcIns의 제외시키고자하는 value값을 가진 Array
 * @returns 공통코드 list
 */
export const getCommonCodeList = async (code, exCdArr) => {
  const context = window?.$nuxt?.context;
  const res = await cdList(code);
  let result = [];
  if (context.$typeUtil.isString(code)) {
    result = res?.list[0]?.itcList[0]?.itgC;
    if (exCdArr) {
      return result.filter(item => !exCdArr.includes(item.itcIns));
    }
  } else {
    // 코드 리스트를 받았을때
    for (let i = 0; i < res?.list.length; i++) {
      const itgCList = res?.list[i]?.itcList[0]?.itgC;
      if (exCdArr) {
        const exCode = exCdArr[i];
        if (exCode) {
          result.push(itgCList.filter(item => !exCode.includes(item.itcIns)));
        } else {
          result.push(itgCList);
        }
      } else {
        result.push(itgCList);
      }
    }
  }
  return result;
};

/**
 * 화면 메타 정보 목록을 가져온다.
 * @param {String} srnId 화면ID
 */
export const getSrnList = async (srnId = commonUtil.getScrnId()) => {
  let srnList; // srnList
  const srnListFromStorage = StorageUtil.store.get('srnList') || [];

  // 현재 화면정보를 찾을수 없다면 일단 서버 재요청
  if (TypeUtil.isEmptyArray(srnListFromStorage)) {
    if (srnId === 'UPFMMA0101M0') {
      // 마이데이터 자산 홈 - 속도 최적화를 위해 srnId 조회하지 않음.
      return;
    }
    const srnListFromServer = (await srnListWithId(srnId))?.list || [];

    // 서버에서 조회한 결과로 Store 갱신
    srnList = srnListFromServer;
    Log.log('[공통-CommonUtil] 화면 리스트 서버 데이터 조회 srnListFromServer: ', srnListFromServer);
    if (!TypeUtil.isEmptyArray(srnListFromServer)) {
      StorageUtil.store.set('srnList', srnListFromServer);
    }
  } else {
    srnList = srnListFromStorage;
    Log.log('[공통-CommonUtil] 화면 리스트 스토리지 데이터 조회 srnListFromStorage: ', srnListFromStorage);
  }
  return srnList;
};

/**
 * 화면 메타 정보를 가져온다.
 * @param {String} srnId 화면ID
 */
export const getSrnInfo = async (srnId = commonUtil.getScrnId()) => {
  let srnList = StorageUtil.store.get('srnList');
  if (srnList == null || srnList.length === 0) {
    srnList = (await getSrnList(srnId)) || [];
  }
  return (
    srnList.filter(srnInf => srnInf.srnId === srnId)[0] || {
      srnId,
      srnNm: '',
      srnUrl: '',
    }
  );
};

/**
 * 로그인 사용자 정보를 가져온다.
 * [조회 우선 순위]
 *   1. Storage 조회
 *   2. 브릿지 호출 (앱 정보조회)
 *   3. 백엔드 sessionInfo 조회 (웹 개발 시에만 사용)
 *
 * @returns {Object} {
 *   {Boolean} success 사용자정보 조회 성공여부
 *   {Object} loginUser(조회한 사용자정보) {
 *     {String} cstMngtNo: 고객관리번호
 *     {String} cstNm: 고객명
 *     {String} mnmCstMngtNo: 모니모고객관리번호
 *   }
 *}
 */
export const getLoginUser = async (context = window?.$nuxt?.context) => {
  Log.log('[공통-CommonUtil] getLoginUser 호출');
  // 로그인 사용자
  let loginUser = null;

  // 1. Storage 조회
  loginUser = StorageUtil.store.get('loginUser');

  // 스토리지에 사용자 정보가 없거나, 유효시간이 지난 경우
  if (loginUser == null) {
    Log.log('[공통-CommonUtil] getLoginUser 스토리지에서 사용자 정보 조회 실패, 앱 데이터 조회');

    // 2. 브릿지 호출 (앱 데이터 조회)
    if (commonUtil.isMobileApp()) {
      let bridgeRes = {};
      const loginFromBridge = async param => {
        if (commonUtil.isMonimoBridgeApp()) {
          const res = await context?.$mnmBridge?.callBridge(context.$mnmBridge.GET_LOGIN_USER_INFO, param);
          Log.log('[공통-CommonUtil] GET_LOGIN_USER_INFO결과 res: ', res);
          return {
            ...res,
            result: res?.resultCode === '00',
          };
        } else {
          const res = await context?.$ongobridge?.getLoginUser({});
          Log.log('[공통-CommonUtil] getLoginUser결과 res: ', res);
          return res?.data;
        }
      };
      bridgeRes = await loginFromBridge();
      Log.log('[공통-CommonUtil] 브릿지 호출 getLoginUser bridgeRes: ', bridgeRes);
      if (bridgeRes && bridgeRes.result) {
        loginUser = bridgeRes;

        // 스토리지에 저장
        StorageUtil.store.set('loginUser', { timestamp: new Date().getTime(), ...loginUser });
      } else {
        Log.log('[공통-CommonUtil] 브릿지 리턴 없음');
      }
    } else {
      // 3. 개발에서는 백엔드 API를 조회한다. (앱 데이터와 동일)
      const serverRes = await sessionInfo();
      Log.log('[공통-CommonUtil] 서버 API sessionInfo serverRes: ', serverRes);
      if (serverRes && serverRes.result) {
        loginUser = serverRes.result;

        // 스토리지에 저장
        StorageUtil.store.set('loginUser', { timestamp: new Date().getTime(), ...loginUser });
      } else if (!serverRes?.success || serverRes?.resultCnt < 1) {
        Log.log('[공통-CommonUtil] sessionInfo 서버 세션 로그아웃 상태');
      }
    }
    return { success: !!loginUser, loginUser };
  } else {
    Log.log('[공통-CommonUtil] 스토리지에서 사용자 정보 조회 성공');
    return { success: true, loginUser };
  }
};

/**
 * Object Deep copy
 */
export const getCloneObject = obj => {
  return commonUtil.getCloneObject(obj);
};

/**
 * 현재 시간을 가져오는 API
 *   1. 서버시간 가져온다
 *   2. 서버시간을 못가져오면 client time을 리턴한다.
 */
export const getCurrentTime = async (context = window?.$nuxt?.context) => {
  // 서버 현재시간을 가져와서 메시지 설정
  const res = await selCurDateTime({});
  let time;
  if (res && res.result) {
    time = context.$dateUtil.parseDateTime(res.result);
  } else {
    // 서버시간을 못가져왔을때 client time
    time = context.$dateUtil.parseDateTime(new Date(), 'YYYYMMDDHHmmss');
  }
  return time;
};

/**
 * 현재 사용자의 계정이 OBT 대상 계정인지 확인하는 함수
 *
 * param {
 *   obtAccountObj: { // OBT를 위한 계정 리스트 Object
 *     dev : ['개발계 모관번'],
 *     prod: ['운영계 모관번'],
 *   }
 * }
 * return {
 *   boolean: OBT 계정인지 아닌지 여부
 * }
 */
export const isObtAccount = async (obtAccountObj, context = window?.$nuxt?.context) => {
  let result = false;
  if (TypeUtil.isObject(obtAccountObj) && !TypeUtil.isEmptyObject(obtAccountObj)) {
    const res = await getLoginUser();
    Log.log('[공통-CommonUtil] isObtAccount loginUser res: ', res);
    if (res && res.success) {
      for (const key in obtAccountObj) {
        if ((key === 'dev' && (commonUtil.isDev() || commonUtil.isTest())) || (key === 'prod' && commonUtil.isProd())) {
          const obtAccountArray = obtAccountObj[key];
          result = obtAccountArray.includes(res.loginUser.mnmCstMngtNo);
        }
      }
    }
  }
  Log.log('[공통-CommonUtil] isObtAccount result: ', result);
  return result;
};

/**
 * 로컬 개발일때는 로컬스토리지, 모바일앱에서는 deviceStorage를 활용해서 값을 저장하는 함수
 *
 * param {
 *   key(string): 스토리지 키
 *   value(string, array, object): 스토리지에 저장하는 값
 *   isObject(boolean): json stringify 처리를 해야하는지 여부 (default: false)
 *   encodeURI(boolean): encodeURIComponent 처리 여부 (default: false)
 * }
 */
export const setLocalStorageData = (
  key,
  value,
  isObject = false,
  encodeURI = false,
  context = window?.$nuxt?.context,
) => {
  // object JSON stringify 처리
  if (isObject || TypeUtil.isObject(value)) {
    value = JSON.stringify(value);
  }
  if (commonUtil.isMobileApp()) {
    // 모바일 일때
    // encodeURIComponent 함수 호출
    if (encodeURI) {
      value = window.encodeURIComponent(value);
    }
    Log.log('[공통-CommonUtil] setLocalStorageData Mobile key: ' + key + ', value: ', value);
    if (commonUtil.isMonimoBridgeApp()) {
      context?.$mnmBridge?.callBridge(context.$mnmBridge.ACCESS_LOCAL_STORAGE, {
        type: 'SAVE', // SAVE(저장)
        key,
        value,
      });
    } else {
      context?.$ongobridge?.setDeviceStorage({
        key,
        value,
      });
    }
  } else if (commonUtil.isDev()) {
    Log.log('[공통-CommonUtil] setLocalStorageData Local key: ' + key + ', value: ', value);
    // 로컬 개발환경 일때
    StorageUtil.localStorage.set(key, value);
  }
};

/**
 * 로컬 개발일때는 로컬스토리지, 모바일앱에서는 deviceStorage를 활용해서 값을 가져오는 함수
 *
 * param {
 *   key(string): 스토리지 키
 *   isObject(boolean): json 파싱 처리를 해야하는지 여부 (default: false)
 *   decodeURI(boolean): decodeURIComponent 처리 여부 (default: false)
 * }
 * return {
 *   value(string, array, object): 스토리지에 저장된 값
 * }
 */
export const getLocalStorageData = async (
  key,
  isObject = false,
  decodeURI = false,
  context = window?.$nuxt?.context,
) => {
  let value = '';
  if (commonUtil.isMobileApp()) {
    // 모바일 일때
    if (commonUtil.isMonimoBridgeApp()) {
      value = (
        await context?.$mnmBridge?.callBridge(context.$mnmBridge.ACCESS_LOCAL_STORAGE, {
          type: 'LOAD', // LOAD(조회)
          key,
        })
      )?.value;
    } else {
      value = (
        await context?.$ongobridge?.getDeviceStorage({
          key,
        })
      )?.data?.value;
    }

    // decodeURIComponent 호출
    if (decodeURI) {
      value = window.decodeURIComponent(value);
    }
    // localStorage에서는 object 파싱이 불필요하지만 string 처리된 deviceStorage 데이터는 파싱이 필요
    if (isObject) {
      Log.log('[공통-CommonUtil] getLocalStorageData Mobile key: ' + key + ', before pasing value: ', value);
      try {
        if (value != null && value !== '') {
          value = JSON.parse(value);
        } else {
          value = null;
        }
      } catch (error) {
        value = null;
        Log.log('[공통-CommonUtil] getLocalStorageData key: ' + key + ', json parsing error: ', error);
      }
    }
    Log.log('[공통-CommonUtil] getLocalStorageData Mobile key: ' + key + ', value: ', value);
  } else if (commonUtil.isDev()) {
    // 로컬 개발환경 일때
    value = StorageUtil.localStorage.get(key);
    Log.log('[공통-CommonUtil] getLocalStorageData Local key: ' + key + ', value: ', value);
  }
  return value;
};

/**
 * 로컬 개발일때는 로컬 세션 스토리지, 모바일 앱에서는 Cache를 활용해서 값을 저장하는 함수
 *
 * param {
 *   key(string): 스토리지 키
 *   value(string, array, object): 스토리지에 저장하는 값
 *   isObject(boolean): json stringify 처리를 해야하는지 여부 (default: false)
 *   encodeURI(boolean): encodeURIComponent 처리 여부 (default: false)
 * }
 */
export const setCacheData = (key, value, isObject = false, encodeURI = false, context = window?.$nuxt?.context) => {
  // object JSON stringify 처리
  if (isObject || TypeUtil.isObject(value)) {
    value = JSON.stringify(value);
  }
  if (commonUtil.isMobileApp()) {
    // 모바일 일때
    // encodeURIComponent 함수 호출
    if (encodeURI) {
      value = window.encodeURIComponent(value);
    }
    Log.log('[공통-CommonUtil] setCacheData Mobile key: ' + key + ', value: ', value);
    if (commonUtil.isMonimoBridgeApp()) {
      context?.$mnmBridge?.callBridge(context.$mnmBridge.ACCESS_CACHE_STORAGE, {
        type: 'SAVE',
        key,
        value,
      });
    } else {
      context?.$ongobridge?.setCacheData({
        key,
        value,
      });
    }
  } else if (commonUtil.isDev()) {
    Log.log('[공통-CommonUtil] setCacheData Local key: ' + key + ', value: ', value);
    // 로컬 개발환경 일때
    StorageUtil.sessionStorage.set(key, value);
  }
};

/**
 * 로컬 개발일때는 로컬 세션 스토리지, 모바일 앱에서는 Cache를 활용해서 값을 가져오는 함수
 *
 * param {
 *   key(string): 스토리지 키
 *   isObject(boolean): json 파싱 처리를 해야하는지 여부 (default: false)
 *   decodeURI(boolean): decodeURIComponent 처리 여부 (default: false)
 * }
 * return {
 *   value(string, array, object): 스토리지에 저장된 값
 * }
 */
export const getCacheData = async (key, isObject = false, decodeURI = false, context = window?.$nuxt?.context) => {
  let value = '';
  if (commonUtil.isMobileApp()) {
    // 모바일 일때
    if (commonUtil.isMonimoBridgeApp()) {
      value = (
        await context?.$mnmBridge?.callBridge(context.$mnmBridge.ACCESS_CACHE_STORAGE, {
          type: 'LOAD',
          key,
        })
      )?.value;
    } else {
      value = (
        await context?.$ongobridge?.getCacheData({
          key,
        })
      )?.data?.value;
    }

    // decodeURIComponent 호출
    if (decodeURI) {
      value = window.decodeURIComponent(value);
    }
    // localStorage에서는 object 파싱이 불필요하지만 string 처리된 deviceStorage 데이터는 파싱이 필요
    if (isObject) {
      Log.log('[공통-CommonUtil] getCacheData Mobile key: ' + key + ', before pasing value: ', value);
      try {
        if (value != null && value !== '') {
          value = JSON.parse(value);
        } else {
          value = null;
        }
      } catch (error) {
        value = null;
        Log.log('[공통-CommonUtil] getCacheData key: ' + key + ', json parsing error: ', error);
      }
    }
    Log.log('[공통-CommonUtil] getCacheData Mobile key: ' + key + ', value: ', value);
  } else if (commonUtil.isDev()) {
    // 로컬 개발환경 일때
    value = StorageUtil.sessionStorage.get(key);
    Log.log('[공통-CommonUtil] getCacheData Local key: ' + key + ', value: ', value);
  }
  return value;
};
